import React, { Fragment, useState, useEffect, useContext } from "react";

//MUI components
import {
    Grid,
    Tooltip,
    CircularProgress,
    IconButton,
    Button,
    Button as MuiButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    MenuItem,
    TextField,
    Switch,
    Checkbox,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

//Icons
import GetAppIcon from "@material-ui/icons/GetApp";
import { ReactComponent as TrashCan } from "../../Icons/trashcanIcon.svg";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

//Custom portal components
import AppContext from "../../Context/AppContext";
import CustomAlert from "../common/Alerts/CustomAlert";
import FooterControls from "../FooterComponents/FooterControls";
import { intebotDataActions, openAIActions, blobStorage } from "../DataFunctions";
import CloseButton from "../common/CloseButton";
import CustomButton from "../common/CustomButton";
import RefreshButton from "../common/RefreshButton";

//Icons
import AgregarIcono from "../../Icons/AgregarIcono";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";

// External Libraries
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import Swal from "sweetalert2";
import { DropzoneArea } from "material-ui-dropzone"; // https://yuvaleros.github.io/material-ui-dropzone/
import isURL from "validator/lib/isURL";

//Custom Portal Components
import AddFile from "./Modals/AddFile";

//CSS sheets
import "./Styles/FAQsettings.css";

import { openAIAcceptedFiles, faqUploadTypes, errorHelper } from "../IntebotCatalog";
import { Check } from "@material-ui/icons";
import e from "cors";

//custom styles
const marks = {
    0: {
        style: {
            color: "#27315d",
            fontSize: "16px",
            position: "absolute",
        },
        label: <strong>0%</strong>,
    },
    100: {
        style: {
            color: "#27315d",
            fontSize: "16px",
            position: "absolute",
        },
        label: <strong>100%</strong>,
    },
};

const ButtonFilled = withStyles((theme) => ({
    root: {
        background: theme.palette.primary.dark,
        borderRadius: 25,
        //border: 0,
        color: "white",
        height: 36,
        padding: "0 30px",
        "&:hover": {
            background: theme.palette.primary.light,
        },
        "&$disabled": {
            color: "white",
            background: theme.palette.other.gray,
        },
    },
    disabled: {},
    label: {
        textTransform: "none",
    },
}))(MuiButton);

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);

export default function FaqSettings() {
    const context = useContext(AppContext);
    const filesInitials = {
        fileDataToDelete: [], //Arreglo de archivos con la propiedad #name, para ser eliminados del Blob storage
        indexOfFile: 0, //posición del archivo a ser remplazado/eliminado
        removeFile: false, //Confirmación del usuario de eliminar un archivo sin subir al blob en el <dialog>
    };

    //state
    const [loading, setLoading] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [kbSources, setKbSources] = useState({});
    const [settings, setSettings] = useState({});
    const [filesKB, setFilesKB] = useState(filesInitials);
    const [previousValue, setPreviousValue] = useState({
        settings: {},
        kbSources: {},
    });
    const [alertState, setAlertState] = useState({});
    const [modalState, setModalState] = useState({
        openDialog: false,
        dialog: {
            title: "",
            message: "",
        },
    });
    const [dataFilesToSend, setDataFilesToSend] = useState([]);
    const { fetchKnowledgeBaseSources, messages, fileDataFAQToSend, getStorageData } = context;
    const { token, subscriptionModule } = getStorageData(["token", "subscriptionModule"]);
    const [openAiFiles, setOpenAiFiles] = useState([]); //archivos de generación de texto
    const [openUploadDialog, setOpenUploadDialog] = useState(false); //estado para abrir el dialogo de subir archivo
    const [textGenerationFiles, setTextGenerationFiles] = useState([]);
    const [uploadType, setUploadType] = useState("file");
    const [uploadURL, setUploadURL] = useState("");
    const [uploadErrors, setUploadErrors] = useState({
        uploadFile: true,
        uploadURL: false,
    });
    const [uploadErrorHelper, setUploadErrorHelper] = useState({
        uploadFile: errorHelper.emptyField,
        uploadURL: errorHelper.removeError,
    });
    const [trainingStatus, setTrainingStatus] = useState("");
    const [elapsedTime, setElapsedTime] = useState("");

    const clearState = () => {
        setUploadErrors({
            uploadFile: true,
            uploadURL: false,
        });
        setUploadErrorHelper({
            uploadFile: errorHelper.emptyField,
            uploadURL: errorHelper.removeError,
        });
        setUploadURL("");
        setTextGenerationFiles([]);
        setUploadType("file");
    };

    useEffect(() => {
        clearState();
    }, [openUploadDialog]);

    const refreshFunction = async () => {
        setLoading(true);

        // obtenemos las fuentes del qna
        let sources = await fetchKnowledgeBaseSources();
        await getTextGenerationFiles();

        if (sources) {
            setSources(sources);
        }

        // establecemos la configuracion de la puntuacion
        const settingsCopy = JSON.parse(JSON.stringify(messages));
        setSettings(settingsCopy);

        setDataFilesToSend(fileDataFAQToSend);
        setLoading(false);

        if (document.readyState === "complete") {
            // cuando se ha completado la carga del documento, esperamos hasta que el slider de configuración sea visible
            function waitForElement(selector) {
                return new Promise((resolve) => {
                    // consultamos si existe el slider y regresamos el elemento
                    if (document.querySelector(selector)) {
                        return resolve(document.querySelector(selector));
                    }

                    // monitoreamos los cambios que se realizan en el DOM hasta que se encuentre el slider
                    const observer = new MutationObserver((mutations) => {
                        if (document.querySelector(selector)) {
                            resolve(document.querySelector(selector));
                            // cuando encuentre el slider, desconectar el observer
                            observer.disconnect();
                        }
                    });

                    // el observer monitorea el document.body, si se agregan o eliminan nodos hijos y los subnodos de los nodos hijo
                    observer.observe(document.body, {
                        childList: true,
                        subtree: true,
                    });
                });
            }

            const elem = await waitForElement(".rc-slider-handle-1");
            // forzamos la posición de la primer marca en el slider
            elem.style.left = settingsCopy.QNA_MAX_SCORE + "%";
        }
    };

    useEffect(() => {
        refreshFunction();
    }, []);

    const convertTo24HourFormat = (time) => {
        const [timePart, modifier] = time.split(" ");
        let [hours, minutes, seconds] = timePart.split(":");

        if (hours === "12") {
            hours = "00";
        }

        if (modifier === "PM") {
            hours = parseInt(hours, 10) + 12;
        }

        return `${hours}:${minutes}:${seconds}`;
    };

    const calculateElapsedTime = (processDate, status) => {
        if (status === "succeeded" || status === "Needs Training") {
            return setElapsedTime("");
        }

        const now = new Date();
        const [hours, minutes, seconds] = convertTo24HourFormat(processDate).split(":");
        const processTime = new Date(now);
        processTime.setHours(hours);
        processTime.setMinutes(minutes);
        processTime.setSeconds(seconds);

        const diff = now - processTime;

        // Check if the difference is negative
        if (diff < 0) {
            return; // Do not update the elapsed time if the time difference is negative
        }

        const diffInMinutes = Math.floor(diff / 60000);
        const diffInSeconds = Math.floor((diff % 60000) / 1000);
        let timeElapsed = `${diffInMinutes}:${diffInSeconds < 10 ? "0" : ""}${diffInSeconds}`;

        setElapsedTime(timeElapsed);
    };

    const getTextGenerationFiles = async (noStatus) => {
        const { updateContextAttribute } = context;
        if (!subscriptionModule.textGeneration) return;
        let res = await openAIActions(null, "getFiles");
        let responseCode = res?.data?.response?.code ? parseInt(res.data.response.code) : 99;

        switch (responseCode) {
            case 0:
                let files = res?.data?.response.files;
                if (!noStatus) {
                    getStatusOpenAIFiles();
                }
                setOpenAiFiles(files);
                setElapsedTime("");
                break;

            default:
                let alertError = {
                    open: true,
                    severity: "error",
                    message: "Ocurrió un error al obtener los archivos de generación de texto.",
                };
                updateContextAttribute("alert", alertError);
                break;
        }
    };

    const getStatusOpenAIFiles = async () => {
        const { updateContextAttribute, sleep } = context;
        let res = await openAIActions(null, "getStatus");

        let responseCode = res?.data?.response?.code ? parseInt(res.data.response.code) : 99;

        switch (responseCode) {
            case 0:
                let status = res?.data?.response?.status;
                let processDate = res?.data?.response?.processDate || "";
                let urlName = res?.data?.response?.urlName || "";

                let statusColor = "";
                let statusDetail = "";
                let statusAlias = "";
                calculateElapsedTime(processDate, status);

                switch (status) {
                    case "succeeded":
                        statusAlias = "Entrenamiento completado";
                        statusColor = "#44AB3F"; //Verde
                        statusDetail = "El modelo está listo para ser utilizado.";
                        break;
                    case "training":
                        statusAlias = "Entrenamiento en proceso";
                        statusColor = "spinner"; //Spinner
                        statusDetail =
                            "El modelo está en proceso de entrenamiento, puede tardar varios minutos.";

                        break;
                    case "Needs Training":
                        statusAlias = "Necesita entrenamiento";
                        statusColor = "#e48922"; //Naranja
                        statusDetail =
                            "Da click en el botón de entrenar para iniciar el proceso de entrenamiento.";
                        break;
                    case "Uploading URL":
                        statusAlias = `Cargando sitio web: ${urlName}`;
                        statusColor = "spinner"; //Spinner
                        statusDetail = "Cargando el sitio web, espera un momento.";

                        break;
                    case "Hubo un error al entrenar/subir":
                        statusAlias = "Error al procesar el archivo, inténtalo de nuevo.";
                        statusColor = "#e22e2e"; //Rojo
                        statusDetail =
                            "Ocurrió un error al procesar el archivo. Inténtalo de nuevo.";
                        break;

                    default:
                        statusAlias = "Error";
                        statusColor = "#e22e2e"; //Rojo
                        statusDetail = "Ocurrió un error al procesar el archivo.";
                        break;
                }
                let statusObj = {
                    status: statusAlias,
                    statusColor: statusColor,
                    statusDetail: statusDetail,
                    processDate: processDate,
                    urlName: urlName,
                };

                setTrainingStatus(statusObj);
                if (status === "training" || status === "Uploading URL") {
                    // si el entrenamiento esta en proceso, esperar a que termine
                    var interval = setInterval(() => {
                        calculateElapsedTime(processDate, status);
                    }, 1000);

                    clearInterval(interval);
                    await sleep(5000);
                    await getStatusOpenAIFiles();
                }

                if (status === "succeeded" || status === "Needs Training") {
                    setElapsedTime("");
                    getTextGenerationFiles(true);
                }

                return statusObj;
            default:
                let alertError = {
                    open: true,
                    severity: "error",
                    message: "Ocurrió un error al obtener los archivos de generación de texto.",
                };
                updateContextAttribute("alert", alertError);
                break;
        }
    };

    const setSources = (sources) => {
        const { messages } = context;
        if (sources.length > 0) {
            let filtered = sources;
            // filtramos las fuentes, para no mostrar los archivos de editorial y los relacionados con la personalidad
            filtered = sources.filter((elem) => {
                return (
                    elem &&
                    elem.source !== "Editorial" &&
                    !elem.displayName.includes("Personalidad_")
                );
            });
            setKbSources(filtered);

            setPreviousValue({
                ...previousValue,
                kbSources: filtered,
                settings: messages,
            });
        }
    };

    /**
     * @description cuando termine de obtener los archivos temporales, los guardamos en el context
     */
    const finishedTempSaveFiles = (dataFilesArray, kbFiles) => {
        const { updateContextAttribute } = context;
        setDataFilesToSend(dataFilesArray);
        setKbSources(kbFiles);

        updateContextAttribute("fileFAQToSaveHasSavedChanges", true);
        updateContextAttribute("fileDataFAQToSend", dataFilesArray);
    };

    const handleCloseDialog = (reason) => {
        if (reason === "clickaway") {
            return;
        }
        setModalState({
            openDialog: false,
            dialog: {
                title: "",
                message: "",
            },
        });
        setOpenUploadDialog(false);
    };

    /**
     * @description eliminar archivos guardados como fuentes
     * @param {Number} index
     * @param {Boolean} confirmDelete
     * @param {Object} item - {displayName, source}
     */
    const removeFileOnTable = (index, confirmDelete, item) => {
        //Dialogo para confirmación de eliminado
        setModalState({
            openDialog: true,
            dialog: {
                title: `¿Deseas eliminar el archivo: ${item.displayName}?`,
            },
            //ponemos en espera la info necesaria para proceder a eliminar
            removeFile: true,
            indexOfFile: index,
            fileToDelete: item,
        });

        if (confirmDelete) {
            const { fileDataToDelete } = filesKB;
            const { updateContextAttribute, fileFAQToSaveHasSavedChanges, fileDataFAQToSend } =
                context;

            // copiamos elementos
            let dataFilesToSendCopy = [...fileDataFAQToSend];

            // copiamos la lista de las fuentes
            let sourcesCopy = JSON.parse(JSON.stringify(kbSources));

            // copiamos elementos que seran enviados a eliminar
            let fileDataToDeleteCopy = [...fileDataToDelete];

            // quitamos el archivo de la lista de fuentes
            sourcesCopy.splice(index, 1);
            //agregamos el nombre del archivo al JSON para eliminar
            let founded = fileDataToDeleteCopy.filter((filaData, index) => {
                if (filaData.name === item) {
                    return filaData.name === item.displayName;
                }
            });

            if (founded.length === 0) {
                fileDataToDeleteCopy.push({ fileName: item.displayName, fileUrl: item.source });
            }

            setKbSources(sourcesCopy);
            setHasChanges(true);

            if (fileFAQToSaveHasSavedChanges) {
                // si hay archivos por subir revisar si se debe eliminar
                let foundedOnSave = dataFilesToSendCopy.findIndex(
                    (fileData) => fileData.name === item
                );
                if (foundedOnSave !== -1) {
                    // si el nombre del archivo coincide con uno de la lista para enviar, eliminarlo de la lista de archivos a enviar
                    dataFilesToSendCopy.splice(foundedOnSave, 1);
                }
            }

            updateContextAttribute(
                "fileFAQToDeleteHasSavedChanges",
                fileDataToDeleteCopy.length > 0 ? true : false
            );
            updateContextAttribute("fileDataFAQToDelete", fileDataToDeleteCopy);
            updateContextAttribute(
                "fileFAQToSaveHasSavedChanges",
                dataFilesToSendCopy.length > 0 ? true : false
            );
            updateContextAttribute("fileDataFAQToSend", dataFilesToSendCopy);

            setFilesKB({
                ...filesKB,
                fileDataToDelete: fileDataToDeleteCopy,
            });
        }
    };

    /**
     * @description guardar los cambios en el slider de puntuación
     */
    const handleSliderChange = (value) => {
        const { updateContextAttribute } = context;
        let settingsCopy = JSON.parse(JSON.stringify(settings));
        //agregamos los valores del slider al setting
        settingsCopy = {
            ...settingsCopy,
            QNA_MAX_SCORE: value[0],
        };
        setSettings(settingsCopy);
        setHasChanges(true);
        updateContextAttribute("settingsHasChanges", true);
    };

    /**
     * @description al guardar enviamos los archivos al blob storage y los guardamos como fuentes de la base de conocimientos
     */
    const onSave = async () => {
        const {
            updateContextAttribute,
            fileFAQToSaveHasSavedChanges,
            fileFAQToDeleteHasSavedChanges,
            sendFilesToBlob,
            fetchKnowledgeBaseSources,
            settingsHasChanges,
        } = context;

        if (settingsHasChanges) {
            //Guardamos los cambios en el context
            updateContextAttribute("messages", settings);
            updateContextAttribute("messagesHasSavedChanges", true);
            updateContextAttribute("settingsHasSavedChanges", true);

            //Guardamos los cambios en el servicio a base de datos de test
            updateContextAttribute("loadingDialog", true);
            updateContextAttribute(
                "LoadingMessage",
                "Estamos realizando la conexión con el servicio."
            );
            settings.token = token;
            let res = await intebotDataActions(
                settings,
                "updateConfigurationVariables&enviroment=Test",
                "BotConfigurationOperations"
            );
            let responseCode = res?.data?.code ? parseInt(res.data.code) : 99; //si no hay respuesta correcta del serivico, mandamos el codigo a 1 para indicar el error
            updateContextAttribute("loadingDialog", false);
            updateContextAttribute("LoadingMessage", "");
            switch (responseCode) {
                case 0:
                    updateContextAttribute("messagesHasChanges", false);
                    updateContextAttribute("messagesHasSavedChanges", true);
                    updateContextAttribute("settingsHasChanges", false);
                    updateContextAttribute("settingsHasSavedChanges", true);
                    let alert = {
                        open: true,
                        severity: "success",
                        message: "Configuración guardada correctamente",
                    };
                    updateContextAttribute("alert", alert);
                    break;
                case 2:
                    //El usuario no tiene permisos para realizar esta acción
                    let alert2 = {
                        open: true,
                        severity: "error",
                        message:
                            "Tu suscripción ha caducado, por favor ponte en contacto con el soporte para actualizar tu plan.",
                    };
                    updateContextAttribute("alert", alert2);
                    break;
                case 3:
                    Swal.fire({
                        icon: "info",
                        title: "Tu cuenta no tiene permisos para editar esta suscripción",
                        text: "Si deseas hacer ediciones ponte en contacto con el administrador de la suscripción.",
                    });
                    break;

                default:
                    //Error en el servicio
                    let alertError = {
                        open: true,
                        severity: "error",
                        message:
                            "Ocurrió un error al guardar la configuración, por favor intenta de nuevo.",
                    };
                    updateContextAttribute("alert", alertError);
                    break;
            }
        }

        if (fileFAQToSaveHasSavedChanges || fileFAQToDeleteHasSavedChanges) {
            let res = await sendFilesToBlob(null, null, null, null, settings);

            // limpiamos los campos cuando se terminó de almacenar
            updateContextAttribute("fileDataFAQToSend", []);
            updateContextAttribute("fileFAQToSaveHasSavedChanges", false);
            updateContextAttribute("fileDataFAQToDelete", []);
            updateContextAttribute("fileFAQToDeleteHasSavedChanges", false);
            updateContextAttribute("faqHasSavedChanges", true);

            if (res && res.status) {
                // actualizar lista de fuentes
                await fetchKnowledgeBaseSources();
            }
        }

        setHasChanges(false);
        setFilesKB(filesInitials);
    };

    /**
     *  @description al cancelar, deshacer los cambios
     * */
    const onCancel = () => {
        const { updateContextAttribute, kbFileSourcesOriginal } = context;

        setKbSources(previousValue.kbSources);
        setSettings(previousValue.settings);
        setHasChanges(false);

        updateContextAttribute("fileFAQToSaveHasSavedChanges", false);
        updateContextAttribute("fileDataToSend", []);
        updateContextAttribute("kbFileSources", kbFileSourcesOriginal);
        updateContextAttribute("fileFAQToDeleteHasSavedChanges", false);
        updateContextAttribute("fileDataToDelete", []);
    };

    function readFile(file) {
        if (!file) return;
        return new Promise((resolve, reject) => {
            const reader = new FileReader();

            // Define what happens once the file is loaded
            reader.onload = function (event) {
                resolve(event.target.result); // Resolve with the file contents
            };

            // Define what happens if there's an error reading the file
            reader.onerror = function (event) {
                reject(event.target.error); // Reject with the error
            };

            // Start reading the file
            reader.readAsDataURL(file);
        });
    }

    const handleFileUpload = async (targetName, targetValue) => {
        let uploadErrorsCopy = JSON.parse(JSON.stringify(uploadErrors));
        let uploadErrorHelperCopy = JSON.parse(JSON.stringify(uploadErrorHelper));
        let uploadURLCopy = JSON.parse(JSON.stringify(uploadURL));
        let textGenerationFilesCopy = JSON.parse(JSON.stringify(textGenerationFiles)); //copia de los archivos de generación de texto
        let uploadTypeCopy = JSON.parse(JSON.stringify(uploadType));

        switch (targetName) {
            case "uploadType":
                uploadTypeCopy = targetValue;
                textGenerationFilesCopy = "";
                uploadURLCopy = "";
                if (targetValue === "url") {
                    uploadErrorHelperCopy.uploadFile = errorHelper.removeError;
                    uploadErrorsCopy.uploadFile = false;
                    uploadErrorHelperCopy.uploadURL = errorHelper.emptyField;
                    uploadErrorsCopy.uploadURL = true;
                    break;
                }
                if (targetValue === "file") {
                    uploadErrorHelperCopy.uploadURL = errorHelper.removeError;
                    uploadErrorsCopy.uploadURL = false;
                    uploadErrorHelperCopy.uploadFile = errorHelper.emptyField;
                    uploadErrorsCopy.uploadFile = true;
                    break;
                }

                break;

            case "uploadFile":
                if (!targetValue) {
                    uploadErrorsCopy[targetName] = true;
                    uploadErrorHelperCopy[targetName] = errorHelper.emptyField;
                    break;
                }
                //save image in base64
                let fileBase64Copy = await readFile(targetValue);
                let fileName = targetValue.name
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")
                    .replace(/ /g, "_");
                let fileType = targetValue.type;
                let uploadedFileCopy = {
                    name: fileName,
                    body: fileBase64Copy.split(",")[1],
                    type: fileType,
                    length: targetValue.size,
                };
                textGenerationFilesCopy = uploadedFileCopy;
                uploadErrorsCopy[targetName] = false;
                uploadErrorHelperCopy[targetName] = errorHelper.removeError;

                break;
            case "uploadURL":
                uploadURLCopy = targetValue;

                if (!isURL(targetValue)) {
                    uploadErrorsCopy[targetName] = true;
                    uploadErrorHelperCopy[targetName] = errorHelper.validURL;
                    break;
                }

                if (!targetValue) {
                    uploadErrorsCopy[targetName] = true;
                    uploadErrorHelperCopy[targetName] = errorHelper.emptyField;
                    break;
                }
                uploadErrorsCopy[targetName] = false;
                uploadErrorHelperCopy[targetName] = errorHelper.removeError;
                break;

            default:
                break;
        }
        setUploadErrors(uploadErrorsCopy);
        setUploadErrorHelper(uploadErrorHelperCopy);
        setUploadURL(uploadURLCopy);
        setTextGenerationFiles(textGenerationFilesCopy);
        setUploadType(uploadTypeCopy);
    };

    const sendFilesToBlob = async () => {
        const { updateContextAttribute } = context;
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Estamos realizando la conexión con el servicio.");
        let responseCode = 99;

        if (uploadType === "url") {
            let data = {
                url: uploadURL,
            };
            let res = await openAIActions(data, "uploadURL");
            responseCode = res?.data?.response?.code ? parseInt(res.data.response.code) : 99;
        }

        if (uploadType === "file" && textGenerationFiles.length > 0) {
            let res = await blobStorage(textGenerationFiles, "uploadFile&uploadType=openai");
            responseCode = res?.data?.code ? parseInt(res.data.code) : 99;
        }

        //sleep para esperar a que se cargen los archivos y se actualice la tabla del get
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");

        switch (responseCode) {
            case 0:
                let alert = {
                    open: true,
                    severity: "success",
                    message: "Archivos guardados correctamente",
                };
                updateContextAttribute("alert", alert);
                setTextGenerationFiles([]);
                setOpenUploadDialog(false);
                getTextGenerationFiles();

                break;

            default:
                let alertError = {
                    open: true,
                    severity: "error",
                    message:
                        "Ocurrió un error al guardar los archivos, por favor intenta de nuevo.",
                };
                updateContextAttribute("alert", alertError);

                break;
        }
    };

    const deleteFileFromOpenAI = async (fileName) => {
        //Ask confirm
        let askConfirm = await Swal.fire({
            title: "¿Estás seguro que deseas eliminar el archivo?",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });

        //Cancelamos el guardado de cambios
        if (askConfirm.isDenied || askConfirm.isDismissed) {
            return;
        }

        const { updateContextAttribute } = context;
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Estamos realizando la conexión con el servicio.");
        let data = {
            name: fileName,
        };

        let res = await blobStorage(data, "deleteFile&uploadType=openai");
        let responseCode = res?.data?.code ? parseInt(res.data.code) : 99;
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");

        switch (responseCode) {
            case 0:
                let alert = {
                    open: true,
                    severity: "success",
                    message: "Archivo eliminado correctamente",
                };
                updateContextAttribute("alert", alert);
                getTextGenerationFiles();
                break;

            default:
                let alertError = {
                    open: true,
                    severity: "error",
                    message: "Ocurrió un error al eliminar el archivo, por favor intenta de nuevo.",
                };
                updateContextAttribute("alert", alertError);
                break;
        }
    };

    const trainSearchIndex = async () => {
        let askConfirm = await Swal.fire({
            title: "¿Estás seguro que deseas iniciar el entrenamiento?",
            showDenyButton: true,
            confirmButtonText: "Si",
            denyButtonText: `No`,
            confirmButtonColor: "#27315d",
            denyButtonColor: "#27315d",
        });
        if (askConfirm.isDenied || askConfirm.isDismissed) {
            return;
        }

        const { updateContextAttribute } = context;
        updateContextAttribute("loadingDialog", true);
        updateContextAttribute("LoadingMessage", "Estamos realizando la conexión con el servicio.");
        let res = await openAIActions(null, "train");
        let responseCode = res?.data?.response?.code ? parseInt(res.data.response.code) : 99;
        updateContextAttribute("loadingDialog", false);
        updateContextAttribute("LoadingMessage", "");
        switch (responseCode) {
            case 0:
                let alert = {
                    open: true,
                    severity: "success",
                    message: "Entrenamiento iniciado correctamente",
                };
                updateContextAttribute("alert", alert);
                getStatusOpenAIFiles();
                break;

            default:
                let alertError = {
                    open: true,
                    severity: "error",
                    message:
                        "Ocurrió un error al iniciar el entrenamiento, por favor intenta de nuevo.",
                };
                updateContextAttribute("alert", alertError);
                break;
        }
    };

    const downloadTemplate = async () => {
        window.open(
            "https://stgaccintebotmultimedia.blob.core.windows.net/multimedia/Formato_Preguntas_Frecuentes.xlsx",
            "_blank",
            "noopener,noreferrer"
        );
    };

    const handleSettingsChange = (targetName, targetValue) => {
        const { updateContextAttribute } = context;
        let settingsCopy = JSON.parse(JSON.stringify(settings));
        settingsCopy[targetName] = targetValue;
        setSettings(settingsCopy);
        setHasChanges(true);
        updateContextAttribute("settingsHasChanges", true);
    };

    return (
        <Fragment>
            <div className="FAQ-settings-container">
                {loading ? (
                    <Grid item xs={12} className="center-loading-icon">
                        <CircularProgress />
                    </Grid>
                ) : (
                    <Grid container>
                        <CustomAlert
                            open={alertState.open}
                            severity={alertState.severity}
                            message={alertState.message}
                            handleCloseAlert={() => setAlertState({ ...alertState, open: false })}
                        />

                        <Grid item xs={12}>
                            <h2>Configuración de puntuación FAQ</h2>
                            <p>Rango de coincidencia</p>
                            <Grid container>
                                {/* slider */}
                                <Range
                                    id="slider-range"
                                    min={1}
                                    max={100}
                                    value={[settings.QNA_MAX_SCORE]}
                                    onChange={handleSliderChange}
                                    allowCross={false}
                                    pushable={1}
                                    trackStyle={[{ backgroundColor: "#B2B2B2" }]}
                                    railStyle={{ background: "#B2B2B2" }}
                                    tipFormatter={(value) =>
                                        value !== -1 ? `${value}` : `${settings.QNA_MAX_SCORE}`
                                    }
                                    tipProps={{
                                        visible: true,
                                        placement: "top",
                                        id: `tooltip-id`,
                                    }}
                                    marks={marks}
                                />
                            </Grid>
                            <Grid container className="settings-FAQ__range-values">
                                <Grid item sm={4}>
                                    <p>
                                        {settings.QNA_MAX_SCORE === 1
                                            ? "0"
                                            : `0 a ${settings.QNA_MAX_SCORE - 1}`}
                                        <br />
                                        Generación de texto
                                    </p>
                                </Grid>
                                <Grid item sm={4} className="text--center">
                                    <p>{/* Espacio vacio donde antiguamente venia *Confusa* */}</p>
                                </Grid>
                                <Grid item sm={4} className="text--right">
                                    <p>
                                        {settings.QNA_MAX_SCORE === 100 ? (
                                            <span>
                                                <br />
                                                Exactamente 100
                                            </span>
                                        ) : (
                                            settings.QNA_MAX_SCORE + " a 100 "
                                        )}
                                        <br />
                                        Encontrada
                                    </p>
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* archivos base de conocimientos */}
                        <Grid item xs={12}>
                            <h2 className="settings-kb">
                                Archivos para responder preguntas frecuentes
                            </h2>
                            {/* boton de descarga */}
                            <div className="">
                                <Tooltip title="Descarga la plantilla de ejemplo para agregar preguntas frecuentes">
                                    <Button
                                        id="downloadBtn"
                                        variant="contained"
                                        onClick={downloadTemplate}
                                        endIcon={<GetAppIcon />}
                                        disableElevation
                                    >
                                        Descargar plantilla
                                    </Button>
                                </Tooltip>
                            </div>

                            {/* tabla con los archivos */}
                            <section className="settings-kb__table">
                                <header className="settings-kb__table-head">Archivos</header>
                                {kbSources && kbSources.length > 0 ? (
                                    kbSources.map((item, i) => (
                                        <div className="settings-kb__table-body" key={i}>
                                            <div className="table-row" key={i}>
                                                <div className="table-cell">
                                                    <a download href={item.source} key={i}>
                                                        {item.displayName}
                                                    </a>
                                                </div>
                                            </div>

                                            {/* select para la personalidad chitchat */}
                                            <Tooltip title="Eliminar archivo">
                                                <IconButton
                                                    color="secondary"
                                                    size="small"
                                                    onClick={() =>
                                                        removeFileOnTable(i, false, item)
                                                    }
                                                >
                                                    <TrashCan width="20px" height="20px" />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    ))
                                ) : (
                                    <div className="settings-kb__table-body empty">
                                        <div className="table-row">
                                            <div className="table-cell empty"></div>
                                        </div>
                                    </div>
                                )}
                            </section>
                            <div className="settings-kb__btn-row">
                                <AddFile
                                    hasChanges={(val) => setHasChanges(val)}
                                    tempSaveFiles={finishedTempSaveFiles}
                                    kbSources={kbSources}
                                    fileDataFAQToSend={dataFilesToSend}
                                ></AddFile>
                            </div>

                            {/* Dialogo de Confirmación de remplazar o eliminar un archivo*/}
                            <Dialog
                                open={modalState.openDialog}
                                onClose={() => handleCloseDialog()}
                            >
                                <DialogTitle>{modalState.dialog.title}</DialogTitle>
                                <DialogContent>{modalState.dialog.message}</DialogContent>
                                <DialogActions>
                                    <ButtonFilled onClick={() => handleCloseDialog()}>
                                        No
                                    </ButtonFilled>
                                    <ButtonFilled
                                        //dependiendo de donde fue invocado el dialogo, en confirmación lo regresamos a la función de invocación
                                        onClick={() => {
                                            const { fileToDelete, removeFile, indexOfFile } =
                                                modalState;

                                            let userConfirmation = true;
                                            if (removeFile) {
                                                removeFileOnTable(
                                                    indexOfFile,
                                                    userConfirmation,
                                                    fileToDelete
                                                );
                                            }
                                            handleCloseDialog();
                                        }}
                                        color="primary"
                                        autoFocus
                                    >
                                        Sí
                                    </ButtonFilled>
                                </DialogActions>
                            </Dialog>
                        </Grid>
                        {subscriptionModule.textGeneration && (
                            <Grid item xs={12}>
                                <div>
                                    <h2>Configuración de generación de texto</h2>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            gap: "5px",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <div>
                                            <label className="top-label-field">
                                                Limitar las respuestas al contenido de los archivos
                                            </label>
                                            <Tooltip title="Selecciona si deseas que las respuestas generadas se limiten al contenido de los archivos de generación de texto">
                                                <HelpOutlineIcon color="primary" fontSize="small" />
                                            </Tooltip>
                                            <Checkbox
                                                name="LIMIT_CONTENT_DATA"
                                                checked={settings.LIMIT_CONTENT_DATA}
                                                onChange={(event) => {
                                                    handleSettingsChange(
                                                        event.target.name,
                                                        event.target.checked
                                                    );
                                                }}
                                                color="primary"
                                            />
                                        </div>
                                        <div>
                                            <label className="top-label-field">
                                                Proporcionar instrucciones y contexto
                                            </label>
                                            <Tooltip title="Proporcione al modelo de generación de texto instrucciones sobre cómo debe comportarse y cualquier contexto al que debe hacer referencia al generar una respuesta. Puede describir la personalidad del asistente, indicarle qué debe y qué no debe responder y cómo dar formato a las respuestas.">
                                                <HelpOutlineIcon color="primary" fontSize="small" />
                                            </Tooltip>
                                            <TextField
                                                name="ROLE_SYSTEM"
                                                value={settings.ROLE_SYSTEM}
                                                onChange={(event) => {
                                                    handleSettingsChange(
                                                        event.target.name,
                                                        event.target.value
                                                    );
                                                }}
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                                multiline
                                            />
                                        </div>
                                    </div>
                                </div>

                                <h2>Archivos para generación de texto</h2>
                                <div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            flexDirection: "column",
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                gap: "5px",
                                            }}
                                        >
                                            {trainingStatus.statusColor === "spinner" ? (
                                                <div className="center-loading-icon">
                                                    <CircularProgress />
                                                </div>
                                            ) : (
                                                <FiberManualRecordIcon
                                                    style={{
                                                        color: trainingStatus.statusColor,
                                                    }}
                                                />
                                            )}
                                            {trainingStatus.status}
                                            <br />
                                            {trainingStatus.statusColor === "spinner" &&
                                                elapsedTime && <span>{elapsedTime}</span>}

                                            <Tooltip title={trainingStatus.statusDetail}>
                                                <HelpOutlineIcon color="primary" fontSize="small" />
                                            </Tooltip>
                                        </div>
                                        <div>
                                            <RefreshButton refreshFunction={refreshFunction} />
                                            <CustomButton
                                                disabled={
                                                    trainingStatus.status !==
                                                        "Necesita entrenamiento" &&
                                                    trainingStatus.status !==
                                                        "Error al procesar el archivo, inténtalo de nuevo."
                                                }
                                                variant="contained"
                                                onClick={() => trainSearchIndex()}
                                            >
                                                Entrenar
                                            </CustomButton>
                                        </div>
                                    </div>
                                </div>
                                <section className="settings-kb__table">
                                    <header className="settings-kb__table-head">Archivos</header>
                                    {openAiFiles && openAiFiles.length > 0 ? (
                                        openAiFiles.map((item, i) => (
                                            <div className="settings-kb__table-body" key={i}>
                                                <div className="table-row" key={i}>
                                                    <div className="table-cell">
                                                        <a st download href={item.url} key={i}>
                                                            {item.name}
                                                        </a>
                                                    </div>
                                                    <Tooltip title="Eliminar archivo">
                                                        <IconButton
                                                            disabled={
                                                                trainingStatus.status ===
                                                                    "Entrenamiento en proceso" ||
                                                                trainingStatus.status ===
                                                                    "Subiendo URL"
                                                            }
                                                            color="secondary"
                                                            size="small"
                                                            onClick={() =>
                                                                deleteFileFromOpenAI(item.name)
                                                            }
                                                        >
                                                            <TrashCan width="20px" height="20px" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="settings-kb__table-body empty">
                                            <div className="table-row">
                                                <div className="table-cell empty"></div>
                                            </div>
                                        </div>
                                    )}
                                </section>

                                <div className="settings-kb__btn-row">
                                    <Dialog
                                        open={openUploadDialog}
                                        onClose={() => {
                                            handleCloseDialog();
                                        }}
                                        disableBackdropClick
                                        disableEscapeKeyDown
                                    >
                                        <DialogTitle
                                            disableTypography
                                            style={{ paddingRight: "30px" }}
                                        >
                                            <h2 style={{ paddingRight: "30px" }}>
                                                Cargar archivo de conocimiento
                                            </h2>
                                            <CloseButton
                                                onClose={() => handleCloseDialog()}
                                                modalTitle="¿Estás seguro que deseas salir?"
                                            >
                                                Los cambios realizados se perderán.
                                            </CloseButton>
                                        </DialogTitle>
                                        <DialogContent>
                                            <Tooltip title="Decide si quieres cargar un archivo o extrar la información de un sitio web">
                                                <label>Selecciona el tipo de carga</label>
                                            </Tooltip>
                                            <TextField
                                                select
                                                name={"uploadType"}
                                                value={uploadType}
                                                fullWidth
                                                variant="outlined"
                                                size="small"
                                                onChange={(event) =>
                                                    handleFileUpload(
                                                        event.target.name,
                                                        event.target.value
                                                    )
                                                }
                                                error={uploadErrors.uploadType}
                                                helperText={uploadErrorHelper.uploadType}
                                            >
                                                {faqUploadTypes.length !== 0 ? (
                                                    faqUploadTypes.map((option, i) => (
                                                        <MenuItem key={i} value={option.type}>
                                                            {option.alias}
                                                        </MenuItem>
                                                    ))
                                                ) : (
                                                    <MenuItem value={""}>
                                                        No hay opciones disponibles
                                                    </MenuItem>
                                                )}
                                            </TextField>

                                            {uploadType === "file" && (
                                                <div>
                                                    <DropzoneArea
                                                        name="uploadFile"
                                                        acceptedFiles={openAIAcceptedFiles}
                                                        dropzoneClass={
                                                            uploadErrors.uploadFile
                                                                ? "dropZoneContainerWithErrorNoWidth"
                                                                : "dropZoneContainerNoWidth"
                                                        }
                                                        filesLimit={1}
                                                        maxFileSize={20000000} //20 MB
                                                        showFileNames
                                                        dropzoneText={
                                                            "Arrastra y suelta un archivo aquí o haz click"
                                                        }
                                                        onChange={(file) => {
                                                            handleFileUpload("uploadFile", file[0]);
                                                        }}
                                                        dropzoneParagraphClass="dropzoneParagraph"
                                                        previewGridClasses={{
                                                            container: "previewGridContainer",
                                                            item: "previewGridItem",
                                                            image: "previewGridImage",
                                                        }}
                                                        //Mensajes del Drop Zone
                                                        getFileAddedMessage={(fileName) =>
                                                            `El archivo ${fileName} fue agregado con éxito`
                                                        }
                                                        getFileLimitExceedMessage={(filesLimit) =>
                                                            ` Número máximo de archivos alcanzados. Solamente se permiten ${filesLimit} archivos `
                                                        }
                                                        getFileRemovedMessage={(fileName) =>
                                                            ` El archivo ${fileName} ha sido eliminado. `
                                                        }
                                                        getDropRejectMessage={(rejectedFile) =>
                                                            ` Este archivo ${rejectedFile.name} no es compatible o Excede el máximo permitido de 4MB `
                                                        }
                                                        alertSnackbarProps={{
                                                            anchorOrigin: {
                                                                vertical: "top",
                                                                horizontal: "right",
                                                            },
                                                        }}
                                                    />
                                                    {uploadErrors?.uploadFile && (
                                                        <span
                                                            style={{
                                                                fontSize: "0.75rem",
                                                                color: "red",
                                                            }}
                                                        >
                                                            {uploadErrorHelper?.uploadFile}
                                                        </span>
                                                    )}
                                                </div>
                                            )}
                                            {uploadType === "url" && (
                                                <div>
                                                    <label className="top-label-field">
                                                        URL del archivo
                                                    </label>
                                                    <Tooltip
                                                        title={`Únicamente se cargará el HTML del sitio web, asegúrate de que el sitio web tenga la información que deseas cargar.`}
                                                    >
                                                        <HelpOutlineIcon
                                                            color="primary"
                                                            fontSize="small"
                                                        />
                                                    </Tooltip>
                                                    <TextField
                                                        name="uploadURL"
                                                        value={uploadURL}
                                                        size="small"
                                                        type="small"
                                                        onChange={(event) =>
                                                            handleFileUpload(
                                                                event.target.name,
                                                                event.target.value
                                                            )
                                                        }
                                                        fullWidth
                                                        variant="outlined"
                                                        error={uploadErrors.uploadURL}
                                                        helperText={uploadErrorHelper.uploadURL}
                                                    />
                                                </div>
                                            )}
                                        </DialogContent>
                                        <DialogActions
                                            style={{
                                                margin: 0,
                                                padding: "20px",
                                                justifyContent: "center",
                                                borderTop: "1px solid #e0e0e0",
                                            }}
                                        >
                                            <ButtonFilled
                                                disabled={
                                                    uploadErrors.uploadFile ||
                                                    uploadErrors.uploadURL
                                                }
                                                //dependiendo de donde fue invocado el dialogo, en confirmación lo regresamos a la función de invocación
                                                onClick={async () => {
                                                    let askConfirm = await Swal.fire({
                                                        title: "¿Estás seguro que deseas guardar?",
                                                        showDenyButton: true,
                                                        confirmButtonText: "Si",
                                                        denyButtonText: `No`,
                                                        confirmButtonColor: "#27315d",
                                                        denyButtonColor: "#27315d",
                                                    });
                                                    //Cancelamos el guardado de cambios
                                                    if (
                                                        askConfirm.isDenied ||
                                                        askConfirm.isDismissed
                                                    ) {
                                                        return;
                                                    }
                                                    sendFilesToBlob();
                                                }}
                                                color="primary"
                                                autoFocus
                                            >
                                                Guardar
                                            </ButtonFilled>
                                        </DialogActions>
                                    </Dialog>

                                    <Tooltip title="Abrir modal para agregar archivo">
                                        <Button
                                            disabled={
                                                trainingStatus.statusColor === "spinner" // si esta en proceso de entrenamiento
                                            }
                                            onClick={() => {
                                                setOpenUploadDialog(true);
                                            }}
                                            startIcon={<AgregarIcono width="15px" height="15px" />}
                                            size="small"
                                        >
                                            Agregar archivo
                                        </Button>
                                    </Tooltip>
                                </div>
                            </Grid>
                        )}
                    </Grid>
                )}
            </div>
            <FooterControls
                disableSave={!hasChanges}
                disableCancel={!hasChanges}
                onSave={() => onSave()}
                onCancel={() => onCancel()}
                showChatBtn={true}
            />
        </Fragment>
    );
}
